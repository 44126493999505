import * as React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface Props {
    name: string;
    onChange: any;
    value: string | number | Date;
    additionalClasses?: string;
}

export const DatePickerField: React.FunctionComponent<Props> =
    ({name, value, onChange, additionalClasses}) => {
        return (
            <DatePicker
                className={"form-control " + (additionalClasses || '')}
                selected={(value && new Date(value)) || null}
                dateFormat="yyyyMMdd"
                onChange={val => {
                    onChange(name, val);
                }}
            />
        );
    };

export default DatePickerField;
