import * as React from 'react';
import Autocomplete from './Autocomplete';
import {ServicesService} from '../services/ServicesService.js';
import {Field} from "formik";


interface Props {
    value: string;
    showValid?: boolean;
    valid: boolean;
    used?: boolean;
    setValue: any;
    name: string;
    submitForm: Function;
    serviceCatalogVersion: string;
}

const ServiceAutocomplete: React.FunctionComponent<Props> = props => {

    return (
        <Autocomplete {...props} service={ServicesService.create(props.serviceCatalogVersion)}/>
    );
};

export default ServiceAutocomplete;
