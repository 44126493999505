export const PATIENT_CASE_DEFAULT_STATE = {
  patient_case: {
    sex: "M",
    age_years: 40,
    age_days: 0,
    age_selection: "1",
    adm_date: null,
    drugs: [],
    tarpos: [{code: "", number: 1}],
    services: [{code: "", active: true, valid: true, used: false, text: '', number: 1, side: ''}],
    groups: [],
  }
};
