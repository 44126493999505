import * as React from 'react';
import ServiceAutocomplete from "../../form-fields/ServiceAutocomplete";
import {Field} from "formik";
import { i18n } from "../../config/i18n";
import {IService} from "../../interfaces";
import DatePicker from "../../form-fields/DatePicker";

interface Props {
    index: number;
    service: IService;
    setFieldValue: Function;
    submitForm: Function;
    arrayHelpers: any;
    serviceCatalogVersion: string;
}

const Service: React.FunctionComponent<Props> = props => {
    const {index, service, setFieldValue, submitForm, arrayHelpers, serviceCatalogVersion} = props;

    return (
        <div key={index}>
            <div className="code_input_div row">
                <div className="col-lg-8 pe-0">
                    <div className={'input-group ' + (!service.active ? 'disabled' : '')}>
                        <div className="col-sm-3 px-0">
                            <ServiceAutocomplete
                                name={`services[${index}].code`}
                                value={service && service.code || ""}
                                setValue={setFieldValue}
                                used={service.used}
                                valid={service.valid}
                                submitForm={submitForm}
                                serviceCatalogVersion={serviceCatalogVersion}
                            />

                        </div>
                        <div className="col-sm-2 px-sm-0">
                            <Field component="select"
                                   name={`services[${index}].side`}
                                   className="form-control form-select"
                                   value={service.side || '-'}>
                                <option value="">-</option>
                                <option value="B">B</option>
                                <option value="R">R</option>
                                <option value="L">L</option>
                            </Field>
                        </div>
                        <div className="col-sm-2 px-0" title={i18n.t('tarpo_number')}>
                            <Field type="number"
                                   name={`services[${index}].number`}
                                   id={`services[${index}].number`}
                                   className={"form-control" + (service.number && service.number > 0 ? "" : " input-invalid")}
                                   value={service && service.number || ''}
                            />
                        </div>
                        <div className="col-sm-2 px-sm-0" title={i18n.t('session_number')}>
                            <Field type="number"
                                   name={`services[${index}].sessionNumber`}
                                   id={`services[${index}].sessionNumber`}
                                   className={"form-control" + (service.sessionNumber && service.sessionNumber > 0 ? "" : " input-invalid")}
                                   value={service && service.sessionNumber || ''}
                            />
                        </div>
                        <div className="col-sm-3 px-0">
                            <DatePicker
                                name={`services[${index}].date`}
                                value={service.date ? Date.parse(service.date) : null}
                                onChange={(v, vi) => {
                                    // set hours to 2 to avoid problems with time zone and summer time conversions
                                    vi.setHours(2);
                                    setFieldValue(v, vi)
                                }}
                                additionalClasses={!service.date || Date.parse(service.date) == NaN ? 'input-invalid' : ''}
                            />
                            <span className="fa fa-check removetag me-3"
                                  title={i18n.t(`${service.active ? 'de' : ''}activate_code`)}
                                  onClick={async () => {
                                      setFieldValue(`services[${index}].active`, !service.active);
                                      await Promise.resolve();
                                      submitForm();
                                  }}/>
                            <span
                                className="fa fa-remove removetag"
                                onClick={async () => {
                                    arrayHelpers.remove(index);
                                    await Promise.resolve();
                                    submitForm();
                                }}
                                title={i18n.t('delete_code')}/>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 code_description">
                    <span>{service.text}</span>
                </div>
            </div>
        </div>
    )
};

export default Service
