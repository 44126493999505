import * as React from 'react';
import AdministrativeData from "../components/patient-case/AdministrativeData"
import Diagnoses from "../components/patient-case/Diagnoses"
import {GroupService} from "../services/GroupService";
import {Formik, Form} from 'formik';
import Tarpos from "../components/patient-case/Tarpos"
import {IGrouperResults, IPatientCase, ISystem} from "../interfaces";
import {PATIENT_CASE_DEFAULT_STATE} from "../utils/patientCaseState";
import Drugs from "../components/patient-case/Drugs";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {CancelToken} from 'apisauce';
import FormButtons from "../components/FormButtons";
import { i18n } from "../config/i18n";
import { History } from 'history';
import GroupResults from "../components/patient-case/GroupResult";
import SystemSelection from "../components/SystemSelection";
import Services from "../components/patient-case/Services";
import MapResults from "../components/patient-case/MapResult";

interface Props {
    systems: ISystem[]
    history: History;
    locale: string;
    urlEncodedPc: string;
    currentSystemId: string;
    showPath: boolean;
    preReleaseToken: string;
}

interface State {
    patient_case: IPatientCase,
    currentSystem: ISystem,
    grouper_results: IGrouperResults
}

export default class SingleCaseGroupForm extends React.Component<Props, State> {
    call: any;

    constructor(props) {
        super(props);

        const systemsById = Object.fromEntries(this.props.systems.map(x => [x.id, x]));

        this.state = {
            currentSystem: systemsById[this.props.currentSystemId] || props.systems[0],
            ...PATIENT_CASE_DEFAULT_STATE
        };
    }

    componentDidMount() {
        const {urlEncodedPc} = this.props;
        this.group(null, urlEncodedPc);
    }

    changeValue(name, value) {
        this.setState(prevState => ({
            patient_case: {
                ...prevState.patient_case,
                [name]: value
            }
        }))
    }

    group(values = null, pc = null) {
        const {history, locale, showPath, preReleaseToken} = this.props;
        const {source} = CancelToken;

        if (this.call) {
            this.call.cancel();
        }
        this.call = source();
        const cancelToken = this.call.token;

        this.setState({grouper_results: undefined}, () =>
        {
            GroupService.create({patient_case: values}, pc, this.state.currentSystem.id, locale, preReleaseToken, cancelToken)
                .then(response => {
                    if (!response.ok && response.problem !== 'CANCEL_ERROR') {
                        toast('Something went wrong', {type: 'error'});
                    }
                    return response;
                })
                .then(response => response.data)
                .then((data) => {
                    if (data && data.patient_case) {
                        let url = `?locale=${locale}&pc=${data.patient_case.url_encoded}&system_id=${this.state.currentSystem.id}`
                        if(showPath)
                            url += '&show_path=true'
                        if(preReleaseToken)
                            url += '&pre_release_token=' + preReleaseToken;
                        history.push(url);
                        data.patient_case.drugs.push({code: '', dose: 0, active: true});
                        data.patient_case.tarpos.push({code: '', number: 1, active: true, tariff: '001', amount: 0.0});
                        data.patient_case.services.push({code: '', number: 1, active: true, valid: true, sessionNumber: 1, date: new Date().toISOString().split('T')[0]});
                        data.patient_case.age_selection = data.patient_case.age_years > 0 ? '1' : '0';
                        this.setState(data);
                    }
                });
        });
    }

    render() {
        const {patient_case, currentSystem, grouper_results} = this.state;
        const {systems, locale, showPath} = this.props;
        const systemsById = Object.fromEntries(systems.map(x => [x.id, x]));
        return (
            <Formik
                initialValues={patient_case}
                enableReinitialize={true}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values, {setSubmitting}) => {
                    this.group(values);
                    setSubmitting(false);
                }}
            >
                {({isSubmitting, values, submitForm, setFieldValue, errors}) => (
                    <Form autoComplete="off">
                        <div className="row vertical-spacer">
                            <div className="col-lg-6 pe-0">
                                <SystemSelection
                                    systems={systems}
                                    currentSystem={currentSystem}
                                    changeSystem={(systemId) => {
                                        this.setState({currentSystem: systemsById[systemId]}, submitForm)
                                    }}  
                                />
                                <AdministrativeData
                                    originalAge={(this.state.patient_case && this.state.patient_case.age_years)}
                                    values={values}
                                    submitForm={submitForm}
                                    setFieldValue={setFieldValue}
                                    flags={{}}
                                />
                                <Diagnoses
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    submitForm={submitForm}
                                    changeValue={this.changeValue.bind(this)}
                                    system={currentSystem}
                                />
                                { currentSystem.is_simulation_grouper ?
                                    <Tarpos
                                        title={i18n.t('services')}
                                        tarpos={values.tarpos}
                                        setFieldValue={setFieldValue}
                                        submitForm={submitForm}
                                        locale={locale}
                                        id="all"
                                        tarmedVersion={currentSystem.catalog_versions.tarmed}
                                        mainDiagnosisUsed={values.main_diagnosis && values.main_diagnosis.used}
                                        serviceCatalogVersion={currentSystem.catalog_versions.service_catalog}
                                    /> :
                                    <Services
                                        title={i18n.t('services')}
                                        services={values.services}
                                        setFieldValue={setFieldValue}
                                        submitForm={submitForm}
                                        id="all"
                                        locale={locale}
                                        system={currentSystem}
                                    />
                                }
                                <Drugs
                                    errors={errors}
                                    drugs={values.tarpos}
                                    setFieldValue={setFieldValue}
                                    submitForm={submitForm}
                                    mainDiagnosisUsed={values.main_diagnosis && values.main_diagnosis.used}
                                />
                            </div>
                            <div className="col-lg-6">
                                <GroupResults
                                    grouperResults={grouper_results}
                                    showPath={showPath}
                                />
                                <MapResults
                                    grouperResults={grouper_results}
                                    system={currentSystem}
                                />
                            </div>
                        </div>
                        <FormButtons isSubmitting={isSubmitting} reset={() => {
                            this.setState({
                                ...PATIENT_CASE_DEFAULT_STATE
                            }, submitForm);
                        }}/>
                    </Form>
                )}
            </Formik>
        );
    }
}
