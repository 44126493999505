import * as React from 'react';
import {IGrouperResults, IMapperLog, ITarpo, ISystem} from "../../interfaces";
import {i18n} from "../../config/i18n";
import {useContext} from "react";

interface Props {
    grouperResults: IGrouperResults;
    system: ISystem
}

const MapResults: React.FunctionComponent<Props> = ({grouperResults, system}) => {
    if (!grouperResults?.mapper_log) return null

    const logKey = (logOrTarpo, code: string) => {
        return code + '#' + (logOrTarpo.sessionNumber || '') + '#' + (logOrTarpo.side || '') + '#' + (logOrTarpo.date || '');
    }

    const mapperLog = grouperResults.mapper_log;
    const addedTardocs = grouperResults.added_tardocs;
    /**
     * logs that will be displayed directly as there is no corresponding TARDOC tarpo:
     * WARNING,TARDOC_VALIDATION_DELETE, LKAAT_VALIDATION_TRIGGER, LKAAT_VALIDATION_NOT_FOUND
     */
    const logLevelsWithoutTardoc = [
        "WARNING",
        "TARDOC_VALIDATION_DELETE",
        "LKAAT_VALIDATION_TRIGGER",
        "LKAAT_VALIDATION_NOT_FOUND",
        "LKAAT_VALIDATION_DUPLICATE",
        "MISSING_MASTER"]
    const logsWithoutTardoc = mapperLog.filter(log => logLevelsWithoutTardoc.includes(log.level));
    const logsWithTardoc = mapperLog.filter(log => !logLevelsWithoutTardoc.includes(log.level));
    const logsByTardocCode = logsWithTardoc.reduce((acc, log) => {
        const key = logKey(log, log.tardocCode);
        if (acc[key]) {
            acc[key].push(log);
        } else {
            acc[key] = [log];
        }
        return acc;
    }, {})



    function displayLogMessage(index: number, log: IMapperLog) {
        if (log.level === "INFO")
            return <div className="pt-2 pb-1 alert alert-info" key={"mapper_log_" + index}>
                {i18n.t("mapper_log_level_INFO")}: {log.serviceCode}, {i18n.t('session_number')} {log.sessionNumber}
                {log.side != 'NONE' &&
                    ", " + i18n.t("service_side") + " " + log.side
                }
            </div>

        const alertClass = log.level === "WARNING" ? "alert-error" : "alert-warning";

        return <div className={"pt-2 pb-1 alert " + alertClass} key={"mapper_log_" + index}>
            <span>
                {i18n.t("mapper_log_level_" + log.level)}: <span dangerouslySetInnerHTML={{__html: log.message}}/>
            </span>
            <div>{i18n.t('service_catalog')}: {log.serviceCode}</div>
            {log.tardocCode &&
                <div>TARDOC: {log.tardocCode}</div>}
        </div>;
    }

    function displayTardocTarpo(index: number, tarpo: ITarpo, logs: IMapperLog[]) {
        return <div className="row" key={"tardoc_" + index}>
            <div className="col-sm-2">
                {tarpo.code}
            </div>
            <div className="col-sm-5">
                {tarpo.text}
            </div>
            <div className="col-sm-1 text-end">
                {tarpo.side == 'NONE' ? '' : tarpo.side}
            </div>
            <div className="col-sm-1 text-end">
                {tarpo.number}
            </div>
            <div className="col-sm-1 text-end">
                {tarpo.sessionNumber}
            </div>
            <div className="col-sm-2">
                {tarpo.date}
            </div>
            {logs.length > 0 && <>
                <div className="col-sm-2"></div>
                <div className="col-sm-10">
                    {logs.map((log, index) => displayLogMessage(index, log))}
                </div>
            </>
            }
        </div>;
    }

    return (
        <div className="code_proposal_box" id="map_results_box">
            <div className="row vertical-spacer">
                <div className="col-sm-12">
                    <h4>{i18n.t("map_results")}
                    <small className="text-secondary ms-2" style={{fontSize: '0.5em'}}>
                        {i18n.t('service_catalog')}: {system.catalog_versions.service_catalog},
                        TARDOC: {system.catalog_versions.tardoc.replace('TARDOC_', '')}
                    </small>
                    </h4>
                </div>
                <div className="col-12 p-0">
                <div className="row">
                        <div className="col-sm-12">
                            <h5>{i18n.t('mapping_hints')}</h5>
                            {logsWithoutTardoc.length == 0 && i18n.t('none')}
                            {logsWithoutTardoc.map((log, index) => displayLogMessage(index, log))}
                        </div>
                        <div className="col-sm-12">
                            <h5>{i18n.t('added_tardocs_title')}</h5>
                            <div className="row text-secondary" style={{fontSize: 8}}>
                                <div className="col-sm-2">
                                    Code
                                </div>
                                <div className="col-sm-5">
                                    {i18n.t('tarpo_text')}
                                </div>
                                <div className="col-sm-1">
                                    {i18n.t('service_side')}
                                </div>
                                <div className="col-sm-1">
                                    {i18n.t('tarpo_number')}
                                </div>
                                <div className="col-sm-1">
                                    {i18n.t('session_number')}
                                </div>
                                <div className="col-sm-2">
                                    {i18n.t('service_date')}
                                </div>
                            </div>
                            {addedTardocs.map((tarpo, index) => displayTardocTarpo(index, tarpo,
                                logsByTardocCode[logKey(tarpo, tarpo.code)]))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MapResults
